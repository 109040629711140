import { objectToQueryString } from "./premiumize";
import * as config from "../../settings.json";
import { traktstore } from "./stores";

class HTTP extends Error {
    constructor(message, code) {
        super(message);

        console.warn(`HTTP ${code}: ${message}`);

        this.code = code;
    }
}

/**
 * Dirty workaround to make read on $traktstore easier
 *
 * @return {any}
 * @private
 */
function _traktstore() {
    return JSON.parse(localStorage.trakt);
}

const HTTP_UNAUTHORIZED = 401;

const TRAKT = "https://api.trakt.tv/";

export async function _trakt(endpoint, options = {}, access_token = undefined) {
    const h = {
        "Content-Type": "application/json",
        "trakt-api-version": 2,
        "trakt-api-key": config.trakt,
    };

    if (access_token) {
        h["Authorization"] = `Bearer ${access_token}`;
    }

    let _resp;

    try {
        _resp = await fetch(`${TRAKT}${endpoint}?` + objectToQueryString(options), {
            headers: h,
        });
    } catch (e) {
        if (!(e instanceof TypeError) || e.message !== "Failed to fetch") {
            throw e;
        } else {
            // TypeError: Failed to fetch
            throw new HTTP("Auth invalid", HTTP_UNAUTHORIZED);
        }
    }

    if (!_resp.ok) {
        const msg = (await _resp.text()) || _resp.statusText;
        throw new HTTP(msg, _resp.status);
    }

    return await _resp.json();
}

/**
 * More human-friendly version of _trakt
 *
 * @param endpoint
 * @param options
 * @return {Promise<{}|any>}
 */
export async function trakt(endpoint, options = {}) {
    let auth = _traktstore()["accesstoken"];

    try {
        return await _trakt(endpoint, options, auth);
    } catch (e) {
        if (!(e instanceof HTTP)) {
            throw e;
        }

        let retry;

        console.log(e.code);

        switch (e.code) {
            case HTTP_UNAUTHORIZED:
                await refresh();
                auth = _traktstore()["accesstoken"];
                retry = true;
                break;
            default:
                retry = false;
                break;
        }
        if (retry) {
            return await _trakt(endpoint, options, auth);
        } else {
            console.log("no retry");
            return {};
        }
    }
}

function _balance(n) {
    // normalize vote count
    return Math.log10(n);
}

export async function my_watchlist(sort) {
    let data = await trakt(`users/me/watchlist/movies/${sort}`, { extended: "full" });

    data = data.filter((row) => row && row.movie && row.movie.status === "released");

    if (sort === "rating") {
        data.sort((el1, el2) => {
            const [movie1, movie2] = [el1.movie, el2.movie];

            // rating diff
            // return movie2.rating - movie1.rating;

            // rating diff, balancing amount of votes
            const movie1_balancer = _balance(movie1.votes);
            const movie2_balancer = _balance(movie2.votes);

            return movie2.rating * movie2_balancer - movie1.rating * movie1_balancer;
        });
    }

    return data;
}

export function redirect_uri() {
    return `${window.location.origin}/redirect`;
}

export async function code_to_accesstoken(code) {
    return await (
        await fetch(`${TRAKT}/oauth/token`, {
            method: "POST",

            body: JSON.stringify({
                code,
                client_id: config.trakt,
                client_secret: config.trakt_secret,
                redirect_uri: redirect_uri(),
                grant_type: "authorization_code",
            }),

            headers: {
                "Content-Type": "application/json",
                "trakt-api-version": 2,
                "trakt-api-key": config.trakt,
            },
        })
    ).json();
}

export async function refreshtoken_to_accesstoken(refresh_token) {
    return await (
        await fetch(`${TRAKT}/oauth/token`, {
            method: "POST",

            body: JSON.stringify({
                refresh_token,
                client_id: config.trakt,
                client_secret: config.trakt_secret,
                redirect_uri: redirect_uri(),
                grant_type: "refresh_token",
            }),

            headers: {
                "Content-Type": "application/json",
                "trakt-api-version": 2,
                "trakt-api-key": config.trakt,
            },
        })
    ).json();
}

export async function refresh() {
    const $traktstore = _traktstore();

    const rt = $traktstore["refreshtoken"];

    const resp = await refreshtoken_to_accesstoken(rt);

    traktstore.update(($traktstore) => {
        $traktstore["accesstoken"] = resp["access_token"];
        $traktstore["refreshtoken"] = resp["refresh_token"];
        return $traktstore;
    });
}
