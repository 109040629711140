<script>
    import Catalog from "./Catalog.svelte";
    import Show from "./Show.svelte";
    import RequireSignin from "../components/RequireSignin.svelte";

    import queryString from "query-string";
    import Movie from "./Movie.svelte";
    import { get_all_files_as_object } from "../js/premiumize";
    import { objectFilestore, metastore } from "../js/stores";
    import { find_meta_info } from "../js/moviedb";
    import { parse_filename } from "../js/helpers";

    // export let mode;
    // let big = mode === 'big';
    let big = true;

    let show_id;
    let movie_id;
    let title;

    export let chromecast = 0;

    let params = queryString.parse(location.search);

    function refresh_params() {
        if (params && params["show_id"]) {
            show_id = params["show_id"];
        } else if (params && params["movie_id"]) {
            movie_id = params["movie_id"];
        }
    }

    refresh_params();

    async function refresh_files(id, type = null) {
        // changed show id, check if file info is cached
        if (id) {
            let file_info = $objectFilestore[id];
            if (!Object.keys($objectFilestore).length || !file_info) {
                // if file info unknown, load it
                const obj = await get_all_files_as_object();
                objectFilestore.update((_) => obj);
                file_info = obj[id];
            }
            if (file_info && !file_info["title"]) {
                // title not parsed yet
                const parsed = parse_filename(file_info["name"]);
                file_info = { type, ...file_info, ...parsed };
            }

            if (file_info) {
                if (!Object.keys($metastore).length || !$metastore[id]) {
                    $metastore[id] = await find_meta_info(file_info["title"], file_info["type"], file_info["year"]);
                }
            }
        }
    }

    // refresh files on show or movie change
    $: show_id, refresh_files(show_id, "tv");
    $: movie_id, refresh_files(movie_id, "movie");

    function popState(event) {
        // triggered when clicking the back button
        // (after selecting movie for example)
        // event.state is null if back, movie/show id if forward
        movie_id = null;
        show_id = null;
        if (event.path[0].location.search) {
            params = queryString.parse(event.path[0].location.search);
            refresh_params();
        }
    }
</script>

<svelte:window on:popstate={popState} />
<RequireSignin {title}>
    {#if show_id}
        <Show bind:id={show_id} bind:title bind:big {chromecast} />
    {:else if movie_id}
        <Movie bind:id={movie_id} bind:title bind:big {chromecast} />
    {:else}
        <Catalog bind:show_id bind:movie_id bind:title bind:big />
    {/if}
</RequireSignin>
