<script>
    import { params } from "../js/helpers";
    import { traktstore } from "../js/stores";
    import { code_to_accesstoken } from "../js/trakt";

    async function main() {
        const code = params().code;

        $traktstore["code"] = code;

        const accesstoken = await code_to_accesstoken(code);

        $traktstore["accesstoken"] = accesstoken["access_token"];
        $traktstore["refreshtoken"] = accesstoken["refresh_token"];

        window.location.href = "/watchlist";
    }

    main();
</script>
