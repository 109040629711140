<script>
    import { metastore, progressStore } from "../js/stores";
    import { parse_filename, responsive_filename_simple, plural } from "../js/helpers";
    import { get_details } from "../js/premiumize";
    import VideoComponent from "../components/VideoComponent.svelte";

    export let id;
    export let title = "Video";
    export let big;

    export let chromecast;

    let data = {};
    let source;
    let sub_src;
    let metadata;

    function format_episode(season, episode) {
        if (!season || !episode) return "";
        return `S${season.toString().padStart(2, 0)}E${episode.toString().padStart(2, 0)}`;
    }

    function set_details(_id) {
        get_details(_id).then((_dat) => {
            if (_dat["status"] === "error") {
                // something went wrong
                const show = $metastore[_id.toLowerCase()];

                if (!show) {
                    window.location.href = "/";
                    return;
                }

                let nextup_id;

                let has_progress = false;
                const unsubscribe = progressStore.subscribe((data) => {
                    if (has_progress) return;
                    if (Object.keys(data).length) {
                        has_progress = true;
                        seasonloop: for (const season of Object.values(show.seasons)) {
                            // episodeloop:
                            for (const episode of Object.values(season.episodes)) {
                                if (!$progressStore[episode.id]) {
                                    nextup_id = episode.id;
                                    _id = episode.id;
                                    set_new_id(nextup_id);
                                    break seasonloop;
                                } else {
                                    if (!$progressStore[episode.id]["done"]) {
                                        nextup_id = episode.id;
                                        _id = episode.id;
                                        break seasonloop;
                                    }
                                }
                            }
                        }
                        set_new_id(nextup_id);
                    }
                });
            }

            source = _dat["stream_link"] || _dat["link"];
            sub_src = "/api/subtitles?query=" + _dat["name"];

            let file = (_dat["file"] = parse_filename(_dat.name));
            title = `${file.title} ${format_episode(file.season, file.episode)}`;
            _dat["show"] = $metastore[file.title.toLowerCase()];
            data = _dat;
            let _on, _poster;

            if (_dat.show && _dat.show.meta) {
                _on = _dat.show.meta.original_name;
                _poster = "https://image.tmdb.org/t/p/w500/" + _dat.show.meta.poster_path;
            } else {
                _on = "-";
                _poster = "";
            }

            metadata = {
                title,
                description: _on,
                poster: _poster,
                time: 1,
                subs: "/api/subtitles?query=" + _dat["name"],

                transcode_status: _dat["transcode_status"],
            };
        });
    }

    set_details(id);

    function set_new_id(_id) {
        id = _id; // makes svelte happy
        window.history.pushState(_id, _id, `?show_id=${_id}`);
        set_details(_id);
    }

    if (big) {
        function first_of_obj(obj) {
            return obj[Object.keys(obj)[0]];
        }

        function last_of_obj(obj) {
            const i = Object.keys(obj);
            return obj[i[i.length - 1]];
        }

        // big screen controls
        jQuery((_) => {
            document.addEventListener("keydown", (event) => {
                if (event.key.startsWith("Arrow")) {
                    event.preventDefault();
                    if (event.key === "ArrowUp") {
                        // next season
                        const next_season = data.show.seasons[data.file.season + 1];
                        if (!next_season) return;

                        let next_episode = next_season.episodes[data.file.episode];
                        if (!next_episode) next_episode = last_of_obj(next_season.episodes);

                        set_new_id(next_episode["id"]);
                    } else if (event.key === "ArrowDown") {
                        // prev season
                        const prev_season = data.show.seasons[data.file.season - 1];
                        if (!prev_season) return;

                        let prev_episode = prev_season.episodes[data.file.episode];
                        if (!prev_episode) prev_episode = last_of_obj(prev_season.episodes);

                        set_new_id(prev_episode["id"]);
                    } else if (event.key === "ArrowLeft") {
                        // prev eps
                        const prev_eps = data.show.seasons[data.file.season].episodes[data.file.episode - 1];
                        if (prev_eps) {
                            set_new_id(prev_eps["id"]);
                        } else {
                            let prev_seas_eps;
                            try {
                                prev_seas_eps = last_of_obj(data.show.seasons[data.file.season - 1].episodes);
                            } catch (e) {
                                return;
                            }

                            if (prev_seas_eps) {
                                set_new_id(prev_seas_eps["id"]);
                            } // else: too bad
                        }
                    } else if (event.key === "ArrowRight") {
                        // next eps
                        const next_eps = data.show.seasons[data.file.season].episodes[data.file.episode + 1];
                        if (next_eps) {
                            set_new_id(next_eps["id"]);
                        } else {
                            // try next season, first eps
                            let next_seas_eps;
                            try {
                                next_seas_eps = first_of_obj(data.show.seasons[data.file.season + 1].episodes);
                            } catch (e) {
                                return;
                            }

                            if (next_seas_eps) {
                                set_new_id(next_seas_eps["id"]);
                            } // else: too bad
                        }
                    }
                }
            });
        });
    }
</script>

<!-- details -->
<section class="section details" id="details-page">
    <!-- details background -->
    {#if data.show && data.show.meta}
        <div
            id="details-image"
            class="details__bg"
            data-bg="https://image.tmdb.org/t/p/w1280/{data.show.meta.backdrop_path}"
        />
    {:else}
        <div class="details__bg" data-bg="/img/home/home__bg.jpg" />
    {/if}

    <!-- end details background -->

    <!-- details content -->
    <div class="container">
        <div class="row">
            <!-- title -->
            <div class="col-12">
                <h1 class="details__title">
                    <a target="_blank" href="https://www.premiumize.me/file?id={id}">
                        {@html responsive_filename_simple(data.name)}
                    </a>
                </h1>
            </div>
            <!-- end title -->

            <!-- content -->
            <div class="col-10">
                <div class="card card--details card--series">
                    <div class="row">
                        <!-- card cover -->
                        <div class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3">
                            <div class="card__cover">
                                {#if data.show && data.show.meta}
                                    <img src="https://image.tmdb.org/t/p/w500/{data.show.meta.poster_path}" alt="" />
                                {:else}
                                    <img src="https://media.comicbook.com/files/img/default-movie.png" />
                                {/if}
                            </div>
                        </div>
                        <!-- end card cover -->

                        <!-- card content -->
                        <div class="col-12 col-sm-8 col-md-8 col-lg-9 col-xl-9">
                            <div class="card__content">
                                <div class="card__wrap">
                                    <span class="card__rate"
                                        ><i class="icon ion-ios-star" />
                                        {#if data.show && data.show.meta}
                                            {data.show.meta.vote_average}
                                        {/if}
                                    </span>

                                    <ul class="card__list">
                                        {#if data.file && data.file.resolution}
                                            <li>{data.file.resolution}</li>
                                        {/if}
                                        {#if data.file && data.file.quality}
                                            <li>{data.file.quality}</li>
                                        {/if}
                                    </ul>
                                </div>

                                <ul class="card__meta">
                                    {#if data.show && data.show.meta}
                                        <li>
                                            <span>Release year:</span>
                                            {data.show.meta.first_air_date.split("-")[0]}
                                        </li>
                                        <li><span>Country:</span> {data.show.meta.origin_country[0]}</li>
                                    {/if}
                                </ul>

                                {#if data.show && data.show.meta}
                                    <div class="card__description card__description--details">
                                        {data.show.meta.overview}
                                    </div>
                                {/if}
                            </div>
                        </div>
                        <!-- end card content -->
                    </div>
                </div>
            </div>
            <!-- end content -->

            <!-- player -->
            <div class="col-12 col-xl-6">
                <VideoComponent viewing_id={id} {source} {sub_src} {metadata} {chromecast} bind:big />
            </div>
            <!-- end player -->

            <!-- accordion -->
            <div class="col-12 col-xl-6">
                <div class="accordion custom_scrollbar" id="accordion">
                    {#if data.show}
                        {#each Object.entries(data.show.seasons) as [season, episodes]}
                            <div class="accordion__card showlist">
                                <div class="card-header" id="heading{season}">
                                    <button
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#collapse{season}"
                                        aria-expanded="false"
                                        aria-controls="collapse{season}"
                                        class="collapsed"
                                    >
                                        <span>Season: {season}</span>
                                        <span>{plural(Object.keys(episodes.episodes).length, "Episode", true)}</span>
                                    </button>
                                </div>

                                <div
                                    id="collapse{season}"
                                    class="collapse"
                                    aria-labelledby="heading{season}"
                                    data-parent="#accordion"
                                >
                                    <div class="card-body">
                                        <table class="accordion__list">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Title</th>
                                                    <th>Filename</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {#each Object.entries(episodes.episodes) as [episode_no, episode]}
                                                    <tr on:click={() => set_new_id(episode.id)}>
                                                        <th>{episode_no}</th>
                                                        <td
                                                            >{episode.title}
                                                            {format_episode(episode.season, episode.episode)}</td
                                                        >
                                                        <td>{episode.name}</td>
                                                    </tr>
                                                {/each}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        {/each}
                    {/if}
                </div>
            </div>
            <!-- end accordion -->
        </div>
    </div>
    <!-- end details content -->
</section>

<!-- end details -->
<style>
    #details-page {
        margin-top: 0;
    }

    #details-image {
        background-position: top !important;
    }

    .custom_scrollbar::-webkit-scrollbar {
        /* some css package should fix this, but that is broken */
        display: none;
    }

    .custom_scrollbar {
        -ms-overflow-style: none;
        overflow-y: scroll;
    }

    h1 a {
        color: white;
    }
</style>
