<script>
    import { Router, Link, Route } from "svelte-routing";

    export let url = "";

    import ShowStandalone from "./pages/ShowStandalone.svelte";
    import MovieStandalone from "./pages/MovieStandalone.svelte";
    import Index from "./pages/Index.svelte";
    import Trending from "./pages/Trending.svelte";
    import Watchlist from "./pages/Watchlist.svelte";
    import TraktRedirect from "./pages/TraktRedirect.svelte";
    import ChromecastIndex from "./pages/ChromecastIndex.svelte";
    import Search from "./pages/Search.svelte";
    import Signin from "./pages/Signin.svelte";
    import NotFound from "./pages/404.svelte";
</script>

<Router {url}>
    <Route path="/" component={Index} />
    <Route path="/trending" component={Trending} />
    <Route path="/show/:id" component={ShowStandalone} />
    <Route path="/movie/:id" component={MovieStandalone} />
    <Route path="/search/" component={Search} />
    <Route path="/login" component={Signin} />
    <Route path="/c" component={ChromecastIndex} />

    <Route path="/watchlist" component={Watchlist} />
    <Route path="/redirect" component={TraktRedirect} />

    <Route component={NotFound} />
</Router>
