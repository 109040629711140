<script>
    import { metastore, objectFilestore } from "../js/stores";

    // export let show_id, movie_id;
    let query, searchInput;

    function reload_data() {
        objectFilestore.update((_) => {
            return {};
        });
        metastore.update((_) => {
            return {};
        });
        window.location.reload();
    }
</script>

<!-- header -->
<header class="header">
    <div class="header__wrap">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="header__content">
                        <!-- header logo -->
                        <a href="/" class="header__logo">
                            <img src="/img/nepflix.png" alt="" />
                        </a>
                        <!-- end header logo -->

                        <!-- header nav -->
                        <ul class="header__nav">
                            <li class="header__nav-item">
                                <a href="/" class="header__nav-link"
                                    >Home
                                    <i class="fad fa-album-collection" style="--fa-secondary-color: #ff55a5;" />
                                </a>
                            </li>
                            <li class="header__nav-item">
                                <a href="/trending" class="header__nav-link"
                                    >Trending
                                    <i class="fad fa-analytics" style="--fa-secondary-color: #ff55a5;" />
                                    <!--<i class="fad fa-vial" style="&#45;&#45;fa-secondary-color: #ff55a5;"></i>-->
                                </a>
                            </li>
                            <li class="header__nav-item">
                                <a href="/watchlist" class="header__nav-link"
                                    >Watchlist<img class="trakt" src="/img/trakt.png" alt="Trakt.tv" />
                                    <!--<i class="fad fa-list" style="&#45;&#45;fa-secondary-color: #ff55a5;"></i>-->
                                </a>
                            </li>
                        </ul>
                        <!-- end header nav -->

                        <!-- header auth -->
                        <div class="header__auth">
                            <button class="header__search-btn" type="button" on:click={searchInput.focus()}>
                                <i class="icon ion-ios-search" />
                            </button>

                            <a href="#" on:click|preventDefault={reload_data} class="header__sign-in">
                                <i class="icon ion-ios-log-in" />
                                <span>Refresh Data</span>
                            </a>
                        </div>
                        <!-- end header auth -->

                        <!-- header menu btn -->
                        <button class="header__btn" type="button">
                            <span />
                            <span />
                            <span />
                        </button>
                        <!-- end header menu btn -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- header search -->
    <form action="/search" class="header__search">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="header__search-content">
                        <input
                            bind:this={searchInput}
                            bind:value={query}
                            id="query"
                            name="s"
                            type="text"
                            placeholder="Search for a Movie or TV Series that you are looking for"
                        />

                        <button type="submit" id="search-btn">search</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <!-- end header search -->
</header>

<!-- end header -->
<style>
    .trakt {
        width: 17px;
        height: 17px;
    }
</style>
