import { writable } from "svelte/store";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, setDoc, getDocs } from "firebase/firestore";
import * as config from "../../settings";
import { diff } from "deep-object-diff";

// abstract store that uses some form of storage
const StorageStore = (key, obj, storage) => {
    const { subscribe, set, update } = writable(obj);
    return {
        subscribe,
        set,
        update,
        useStore: () => {
            const json = storage.getItem(key);
            if (json && json !== "undefined") {
                set(JSON.parse(json));
            }

            subscribe((current) => {
                storage.setItem(key, JSON.stringify(current));
            });
        },
    };
};

export let firebase_app;

// debug
// function bench(func) {
//     let startTime = performance.now()
//     const result = func();
//     let endTime = performance.now()
//     console.debug(func, `call took ${endTime - startTime} milliseconds`)
//     return result
// }

const FirebaseStore = (data_coll) => {
    // Initialize Firebase
    firebase_app = initializeApp(config.firebase);

    let cache = {};

    const db = getFirestore(firebase_app);

    const { subscribe, set, update } = writable({});
    return {
        subscribe,
        set,
        update,
        getCache: () => {
            return cache;
        },
        setCollection: (collection_name) => {
            data_coll = collection_name;
        },
        useStore: async () => {
            // get data from firebase and set()
            const progress = {};
            const progress_collection = collection(db, data_coll);

            const data = await getDocs(progress_collection);

            data.forEach((doc) => {
                progress[doc.id] = doc.data();
            });

            // save to ram/cache
            set(progress);

            subscribe((current) => {
                // write (changes) to firebase and update cache

                if (Object.keys(cache).length && Object.keys(current).length) {
                    // if actual change, look for diff
                    for (const key in diff(cache, current)) {
                        // if we want the change info too:
                        // const [key, change] of Object.entries(diff(...))

                        // upload to firebase
                        setDoc(doc(db, data_coll, key), current[key]);
                    }
                }

                // update cache after change
                cache = Object.assign({}, current);
            });
        },
    };
};

// implementation of StorageStore with localstorage
const localStorageStore = (key, obj) => {
    const { useStore, ...methods } = StorageStore(key, obj, localStorage);
    return { useLocalStorage: useStore, useStore, ...methods };
};

// implementation of StorageStore with session
const sessionStorageStore = (key, obj) => {
    const { useStore, ...methods } = StorageStore(key, obj, sessionStorage);
    return { useSessionStorage: useStore, useStore, ...methods };
};

// extension to store objects
const StorageObjectStore = (key, original_store = localStorageStore) => {
    const { set, update, ...methods } = original_store(key, {});
    return {
        update,
        ...methods,
        set: (k, v) => {
            update((s) => {
                s[k] = v;
                return s;
            });
        },
        add: (k, sk = "count", v = 1) => {
            let new_count;

            update((s) => {
                // trippy, but it works
                let sub = s[k] || {};
                new_count = sub[sk] = (sub[sk] || 0) + v;
                s[k] = sub;
                return s;
            });

            return new_count;
        },
        pop: (k) => {
            update((s) => {
                delete s[k];
                return s;
            });
        },
    };
};

// extension to store lists
const StorageListStore = (key, original_store = localStorageStore) => {
    const { update, ...methods } = original_store(key, []);

    return {
        update,
        ...methods,
        push: (value) => {
            update((c) => {
                c.push(value);
                return c;
            });
        },
        pop: (index) => {
            update((c) => {
                c.pop(index);
                return c;
            });
        },
    };
};

// export const filestore = StorageListStore('sync_files', sessionStorageStore);
// filestore.useStore();

export const objectFilestore = StorageObjectStore("sync_files_obj", sessionStorageStore);
objectFilestore.useStore();

export const metastore = StorageObjectStore("meta_info", sessionStorageStore);
metastore.useStore();

export const traktstore = StorageObjectStore("trakt", localStorageStore);
traktstore.useStore();

export const progressStore = FirebaseStore("progress");

export const userStore = writable(undefined);

userStore.subscribe((user) => {
    if (user) {
        progressStore.setCollection(`progress-${user.uid}`);
        progressStore.useStore();
    }
});

// window.gc = progressStore.getCache;
