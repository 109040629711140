// exposes to window.parser and window.path
import "../browserify/browserified";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import queryString from "query-string";
import { userStore } from "./stores";

String.prototype.replaceAll = (string, from, to) => string.split(from).join(to);

export function responsive_filename_simple(name) {
    return String.prototype.replaceAll(filename_simple(name), ".", ".&shy;");
}

export function filename_simple(name) {
    return path.basename(
        name, // strip folders
        path.extname(name) // strip extension
    );
}

export function parse_filename(name) {
    return parser(filename_simple(name));
}

export function plural(count, word, show_no = false) {
    return `${show_no ? count : ""} ${count === 1 ? word : word + "s"}`;
}

export async function require_signin(from) {
    return new Promise((resolve, reject) => {
        onAuthStateChanged(getAuth(), (user) => {
            if (!user) {
                window.location.href = "/login?from=" + from;
                return reject(user);
            }
            userStore.set(user);
            return resolve(user);
        });
    });
}

export async function require_anonymous(from) {
    return new Promise((resolve, reject) => {
        onAuthStateChanged(getAuth(), (user) => {
            if (user) {
                window.location.href = from;
                return reject(user);
            }
            return resolve(user);
        });
    });
}

export function params() {
    return queryString.parse(location.search);
}

export function get_from() {
    const query = params();
    return query.from || "/";
}

export function round(number, decimals = 2) {
    const n = 10 ** decimals;

    return Math.round(number * n) / n || "?";
}

export function str(s) {
    return s + "";
}

export function upper(s) {
    // NOT SUPPORTED: s ||= '';
    return (s || "").toUpperCase();
}

export function humanize(i) {
    const hours = Math.floor(i / 60);
    const minutes = i % 60;
    if (!hours) {
        return minutes + "m";
    }
    return `${hours}h${minutes}m`;
}
