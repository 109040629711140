<script>
    export let source, metadata;
    // poster, releaseDate, title, subtitle = from metadata

    let cjs;

    function setup_castjs() {
        // Create new Castjs instance
        cjs = new Castjs();
    }

    function cast() {
        if (!cjs) {
            // only once
            setup_castjs();
        }

        // console.log(source, metadata);
        // return;

        // Check if casting is available
        if (cjs && cjs.available) {
            // -> cjs?.available but that didnt work
            // Initiate new cast session
            cjs.cast(source, {
                poster: `https://image.tmdb.org/t/p/w500/${metadata.poster_path}`,
                releaseDate: metadata.release_date,
                title: metadata.title,
                subtitle: "Watching on Nepflix",
            });
        }
    }
</script>

<svelte:head>
    <script src="https://castjs.io/cast.min.js"></script>
</svelte:head>

{#if source}
    <button id="cast" type="button" class="form__btn" on:click={cast}>Cast</button>
{/if}
