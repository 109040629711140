<script>
    import Generic from "./Generic.svelte";
    import { require_signin, get_from } from "../js/helpers";
    import Spinner from "./Spinner.svelte";
    import { setContext } from "svelte";

    export let title = "Loading...";
    const from = get_from();

    let user;

    setContext("user", user);
</script>

<Generic {title}>
    {#await require_signin(from)}
        <Spinner />
    {:then user}
        <slot />
    {/await}
</Generic>
