<script>
    import Generic from "../components/Generic.svelte";
    import {
        getAuth,
        setPersistence,
        browserSessionPersistence,
        browserLocalPersistence,
        inMemoryPersistence,
        signInWithEmailAndPassword,
    } from "firebase/auth";
    import { require_anonymous, get_from } from "../js/helpers";
    import Spinner from "../components/Spinner.svelte";

    let email;
    let password;
    let error_msg;
    const from = get_from();

    require_anonymous(from);

    const auth = getAuth();

    let do_remember = true;
    async function signin() {
        // https://stackoverflow.com/questions/69038593/how-to-use-setpersistence-in-firebase-modular-sdk-v9
        const SESSION = browserSessionPersistence;
        const LOCAL = browserLocalPersistence;
        const NONE = inMemoryPersistence;

        const persistence = do_remember ? LOCAL : SESSION;

        await setPersistence(auth, persistence);

        try {
            await signInWithEmailAndPassword(auth, email, password);
            window.location.href = from;
        } catch (error) {
            error_msg = error.message;
        }
    }
</script>

<Generic show_header={false} show_footer={false} show_title={false}>
    <div
        class="sign section--bg"
        data-bg="/img/section/section.jpg"
        style="background: url(&quot;img/section/section.jpg&quot;) center center / cover no-repeat;"
    >
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="sign__content">
                        {#await require_anonymous(from)}
                            <Spinner />
                        {:then _}
                            <!-- authorization form -->
                            <form action="#" method="POST" class="sign__form" on:submit|preventDefault={signin}>
                                <a href="#" class="sign__logo">
                                    <img src="/img/nepflix.png" alt="" />
                                </a>
                                <p class="feedback">
                                    {error_msg || ""}
                                </p>

                                <div class="sign__group">
                                    <input
                                        type="text"
                                        bind:value={email}
                                        class="sign__input"
                                        placeholder="Email"
                                        required
                                    />
                                </div>

                                <div class="sign__group">
                                    <input
                                        type="password"
                                        bind:value={password}
                                        class="sign__input"
                                        placeholder="Password"
                                        required
                                    />
                                </div>

                                <div class="sign__group sign__group--checkbox">
                                    <input id="remember" name="remember" type="checkbox" bind:checked={do_remember} />
                                    <label for="remember">Remember Me</label>
                                </div>

                                <button class="sign__btn" type="submit">Sign in</button>
                            </form>
                            <!-- end authorization form -->
                        {/await}
                    </div>
                </div>
            </div>
        </div>
    </div>
</Generic>

<style>
    .feedback {
        color: white;
        max-width: 280px;
    }
</style>
