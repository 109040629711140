<script>
    import { create_transfer, objectToQueryString, request_GET } from "../js/premiumize";
    import queryString from "query-string";
    import RequireSignin from "../components/RequireSignin.svelte";
    import Spinner from "../components/Spinner.svelte";

    let prefill = queryString.parse(window.location.search)["s"];
    let results;
    let query;
    let n = 10; // 20

    let show_alert;

    if (prefill) {
        query = prefill;
        search();
    }

    async function search() {
        if (query.startsWith("magnet:")) {
            return await download_file(query);
        }

        results = "loading";
        window.history.pushState(query, query, `/search?s=${query}`);

        let params = {
            query,
            n,
        };

        const search_type = (document.querySelector("#search_type") || { value: "Any" }).value;
        const search_quality = (document.querySelector("#search_quality") || { value: "Any" }).value;

        // search types
        if (search_type === "TV-Shows") {
            params["type"] = "TV";
        } else if (search_type === "Movies") {
            params["type"] = "Movies";
        }

        // search qualities
        if (search_quality.toLowerCase() !== "any") {
            params["query"] += " " + search_quality;
        }

        const response = await fetch(`/api/torrents?${objectToQueryString(params)}`);
        results = await response.json();
    }

    function loadMore() {
        n += 5;
        search();
    }

    async function download_file(magnet) {
        const prem_response = await create_transfer(magnet);
        const file_id = await get_transfer(prem_response.id);
        if (file_id) {
            window.location.href = "/?movie_id=" + file_id;
        } else {
            show_alert = true;
        }
    }

    async function get_magnet(magnet) {
        // used to be a seperate step to convert torrent URL to magnet,
        // but with the api v2 we get the magnet link directly.
        return await download_file(magnet);
    }

    async function get_transfer(id) {
        const transfers = await request_GET("transfer/list");
        for (const transfer of transfers["transfers"]) {
            if (transfer["id"] === id) {
                if (transfer["file_id"]) {
                    return transfer["file_id"];
                } else {
                    // lookup folder id, get video file
                    const folder = await request_GET("folder/list", { id: transfer["folder_id"] });
                    for (const content of folder["content"]) {
                        if (content["mime_type"] && content["mime_type"].startsWith("video")) {
                            // heyo found a vid!
                            return content["id"];
                        }
                    }
                }
            }
        }
    }
</script>

<svelte:head>
    <link rel="stylesheet" href="/css/admin.css" />
</svelte:head>

<RequireSignin title="Search Results">
    <section class="section" on:click|once={(_) => window.setup()}>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12">
                            <form action="#" on:submit|preventDefault={search} class="form form--contacts">
                                <input
                                    type="text"
                                    class="form__input"
                                    placeholder="Big Buck Bunny"
                                    bind:value={query}
                                    id="name"
                                    name="name"
                                />
                                <!--                                <button type="button" class="form__btn">Search</button>-->

                                <div class="filter">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="filter__content">
                                                    <div class="filter__items">
                                                        <!-- filter item -->
                                                        <div class="filter__item" id="filter__type">
                                                            <span class="filter__item-label">TYPE:</span>

                                                            <div
                                                                class="filter__item-btn dropdown-toggle"
                                                                role="navigation"
                                                                id="filter-type"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                            >
                                                                <input value="Any" id="search_type" type="button" />
                                                                <span />
                                                            </div>

                                                            <ul
                                                                class="filter__item-menu dropdown-menu scrollbar-dropdown"
                                                                aria-labelledby="filter-type"
                                                            >
                                                                <li>Any</li>
                                                                <li>Movies</li>
                                                                <li>TV-Shows</li>
                                                            </ul>
                                                        </div>
                                                        <!-- end filter item -->

                                                        <!-- filter item -->
                                                        <div class="filter__item" id="filter__quality">
                                                            <span class="filter__item-label">QUALITY:</span>

                                                            <div
                                                                class="filter__item-btn dropdown-toggle"
                                                                role="navigation"
                                                                id="filter-quality"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                            >
                                                                <input value="Any" id="search_quality" type="button" />
                                                                <span />
                                                            </div>

                                                            <ul
                                                                class="filter__item-menu dropdown-menu scrollbar-dropdown"
                                                                aria-labelledby="filter-quality"
                                                            >
                                                                <li>Any</li>
                                                                <li>1080p</li>
                                                                <li>720p</li>
                                                            </ul>
                                                        </div>
                                                        <!-- end filter item -->
                                                    </div>

                                                    <!-- filter btn -->
                                                    <button class="filter__btn" type="button" on:click={search}
                                                        >Search
                                                    </button>
                                                    <!-- end filter btn -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            {#if results}
                                <div class="dashbox">
                                    <div class="dashbox__title">
                                        <h3><i class="icon ion-ios-download" /> Results</h3>

                                        {#if results !== "loading" && results.length}
                                            <div class="dashbox__wrap">
                                                <a class="dashbox__more" href="#" on:click|preventDefault={loadMore}
                                                    >More...</a
                                                >
                                            </div>
                                        {/if}
                                    </div>
                                    {#if show_alert}
                                        <div class="col-4">
                                            <div class="stats">
                                                <br />
                                                <p>Success!</p>
                                                <span
                                                    >File downloaded, but we could not find the streaming link (yet).</span
                                                >
                                                <span>Please check yourself on the <a href="/">homepage</a>!</span>
                                                <i class="icon ion-ios-checkmark" />
                                            </div>
                                        </div>
                                    {/if}
                                    <div class="dashbox__table-wrap mCustomScrollbar _mCS_2" style="overflow: visible;">
                                        <div
                                            id="mCSB_2"
                                            class="mCustomScrollBox mCS-custom-bar3 mCSB_horizontal mCSB_outside"
                                            tabindex="0"
                                            style="max-height: none;"
                                        >
                                            <div
                                                id="mCSB_2_container"
                                                class="mCSB_container"
                                                style="position: relative; top: 0px; left: 0px; width: 501px; min-width: 100%; overflow-x: inherit;"
                                                dir="ltr"
                                            >
                                                {#if results !== "loading" && results.length}
                                                    <table class="main__table main__table--dash">
                                                        <thead>
                                                            <tr>
                                                                <th>TITLE</th>
                                                                <th>TIME</th>
                                                                <th>SEEDS</th>
                                                                <th>PROVIDER</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {#each results as result}
                                                                <tr>
                                                                    <a
                                                                        href="#"
                                                                        on:click|preventDefault={(_) =>
                                                                            get_magnet(result.magnet)}
                                                                    >
                                                                        <td>
                                                                            <div class="main__table-text">
                                                                                {result.title}
                                                                            </div>
                                                                        </td>
                                                                    </a>
                                                                    <td>
                                                                        <div class="main__table-text">
                                                                            {result.time}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="main__table-text">
                                                                            {result.seeds}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="main__table-text">
                                                                            {result.provider}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            {/each}
                                                        </tbody>
                                                    </table>
                                                {:else if results === "loading"}
                                                    <Spinner />
                                                {:else}
                                                    <span class="main__table-text--red">
                                                        Nothing was found for this query.
                                                    </span>
                                                {/if}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            {/if}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</RequireSignin>

<style>
    .filter {
        margin: 0;
    }
</style>
