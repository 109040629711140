(function () {
    function r(e, n, t) {
        function o(i, f) {
            if (!n[i]) {
                if (!e[i]) {
                    var c = "function" == typeof require && require;
                    if (!f && c) return c(i, !0);
                    if (u) return u(i, !0);
                    var a = new Error("Cannot find module '" + i + "'");
                    throw ((a.code = "MODULE_NOT_FOUND"), a);
                }
                var p = (n[i] = { exports: {} });
                e[i][0].call(
                    p.exports,
                    function (r) {
                        var n = e[i][1][r];
                        return o(n || r);
                    },
                    p,
                    p.exports,
                    r,
                    e,
                    n,
                    t
                );
            }
            return n[i].exports;
        }
        for (var u = "function" == typeof require && require, i = 0; i < t.length; i++) o(t[i]);
        return o;
    }
    return r;
})()(
    {
        1: [
            function (require, module, exports) {
                (function (process) {
                    (function () {
                        // .dirname, .basename, and .extname methods are extracted from Node.js v8.11.1,
                        // backported and transplited with Babel, with backwards-compat fixes

                        // Copyright Joyent, Inc. and other Node contributors.
                        //
                        // Permission is hereby granted, free of charge, to any person obtaining a
                        // copy of this software and associated documentation files (the
                        // "Software"), to deal in the Software without restriction, including
                        // without limitation the rights to use, copy, modify, merge, publish,
                        // distribute, sublicense, and/or sell copies of the Software, and to permit
                        // persons to whom the Software is furnished to do so, subject to the
                        // following conditions:
                        //
                        // The above copyright notice and this permission notice shall be included
                        // in all copies or substantial portions of the Software.
                        //
                        // THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
                        // OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
                        // MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN
                        // NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
                        // DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
                        // OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
                        // USE OR OTHER DEALINGS IN THE SOFTWARE.

                        // resolves . and .. elements in a path array with directory names there
                        // must be no slashes, empty elements, or device names (c:\) in the array
                        // (so also no leading and trailing slashes - it does not distinguish
                        // relative and absolute paths)
                        function normalizeArray(parts, allowAboveRoot) {
                            // if the path tries to go above the root, `up` ends up > 0
                            var up = 0;
                            for (var i = parts.length - 1; i >= 0; i--) {
                                var last = parts[i];
                                if (last === ".") {
                                    parts.splice(i, 1);
                                } else if (last === "..") {
                                    parts.splice(i, 1);
                                    up++;
                                } else if (up) {
                                    parts.splice(i, 1);
                                    up--;
                                }
                            }

                            // if the path is allowed to go above the root, restore leading ..s
                            if (allowAboveRoot) {
                                for (; up--; up) {
                                    parts.unshift("..");
                                }
                            }

                            return parts;
                        }

                        // path.resolve([from ...], to)
                        // posix version
                        exports.resolve = function () {
                            var resolvedPath = "",
                                resolvedAbsolute = false;

                            for (var i = arguments.length - 1; i >= -1 && !resolvedAbsolute; i--) {
                                var path = i >= 0 ? arguments[i] : process.cwd();

                                // Skip empty and invalid entries
                                if (typeof path !== "string") {
                                    throw new TypeError("Arguments to path.resolve must be strings");
                                } else if (!path) {
                                    continue;
                                }

                                resolvedPath = path + "/" + resolvedPath;
                                resolvedAbsolute = path.charAt(0) === "/";
                            }

                            // At this point the path should be resolved to a full absolute path, but
                            // handle relative paths to be safe (might happen when process.cwd() fails)

                            // Normalize the path
                            resolvedPath = normalizeArray(
                                filter(resolvedPath.split("/"), function (p) {
                                    return !!p;
                                }),
                                !resolvedAbsolute
                            ).join("/");

                            return (resolvedAbsolute ? "/" : "") + resolvedPath || ".";
                        };

                        // path.normalize(path)
                        // posix version
                        exports.normalize = function (path) {
                            var isAbsolute = exports.isAbsolute(path),
                                trailingSlash = substr(path, -1) === "/";

                            // Normalize the path
                            path = normalizeArray(
                                filter(path.split("/"), function (p) {
                                    return !!p;
                                }),
                                !isAbsolute
                            ).join("/");

                            if (!path && !isAbsolute) {
                                path = ".";
                            }
                            if (path && trailingSlash) {
                                path += "/";
                            }

                            return (isAbsolute ? "/" : "") + path;
                        };

                        // posix version
                        exports.isAbsolute = function (path) {
                            return path.charAt(0) === "/";
                        };

                        // posix version
                        exports.join = function () {
                            var paths = Array.prototype.slice.call(arguments, 0);
                            return exports.normalize(
                                filter(paths, function (p, index) {
                                    if (typeof p !== "string") {
                                        throw new TypeError("Arguments to path.join must be strings");
                                    }
                                    return p;
                                }).join("/")
                            );
                        };

                        // path.relative(from, to)
                        // posix version
                        exports.relative = function (from, to) {
                            from = exports.resolve(from).substr(1);
                            to = exports.resolve(to).substr(1);

                            function trim(arr) {
                                var start = 0;
                                for (; start < arr.length; start++) {
                                    if (arr[start] !== "") break;
                                }

                                var end = arr.length - 1;
                                for (; end >= 0; end--) {
                                    if (arr[end] !== "") break;
                                }

                                if (start > end) return [];
                                return arr.slice(start, end - start + 1);
                            }

                            var fromParts = trim(from.split("/"));
                            var toParts = trim(to.split("/"));

                            var length = Math.min(fromParts.length, toParts.length);
                            var samePartsLength = length;
                            for (var i = 0; i < length; i++) {
                                if (fromParts[i] !== toParts[i]) {
                                    samePartsLength = i;
                                    break;
                                }
                            }

                            var outputParts = [];
                            for (var i = samePartsLength; i < fromParts.length; i++) {
                                outputParts.push("..");
                            }

                            outputParts = outputParts.concat(toParts.slice(samePartsLength));

                            return outputParts.join("/");
                        };

                        exports.sep = "/";
                        exports.delimiter = ":";

                        exports.dirname = function (path) {
                            if (typeof path !== "string") path = path + "";
                            if (path.length === 0) return ".";
                            var code = path.charCodeAt(0);
                            var hasRoot = code === 47; /*/*/
                            var end = -1;
                            var matchedSlash = true;
                            for (var i = path.length - 1; i >= 1; --i) {
                                code = path.charCodeAt(i);
                                if (code === 47 /*/*/) {
                                    if (!matchedSlash) {
                                        end = i;
                                        break;
                                    }
                                } else {
                                    // We saw the first non-path separator
                                    matchedSlash = false;
                                }
                            }

                            if (end === -1) return hasRoot ? "/" : ".";
                            if (hasRoot && end === 1) {
                                // return '//';
                                // Backwards-compat fix:
                                return "/";
                            }
                            return path.slice(0, end);
                        };

                        function basename(path) {
                            if (typeof path !== "string") path = path + "";

                            var start = 0;
                            var end = -1;
                            var matchedSlash = true;
                            var i;

                            for (i = path.length - 1; i >= 0; --i) {
                                if (path.charCodeAt(i) === 47 /*/*/) {
                                    // If we reached a path separator that was not part of a set of path
                                    // separators at the end of the string, stop now
                                    if (!matchedSlash) {
                                        start = i + 1;
                                        break;
                                    }
                                } else if (end === -1) {
                                    // We saw the first non-path separator, mark this as the end of our
                                    // path component
                                    matchedSlash = false;
                                    end = i + 1;
                                }
                            }

                            if (end === -1) return "";
                            return path.slice(start, end);
                        }

                        // Uses a mixed approach for backwards-compatibility, as ext behavior changed
                        // in new Node.js versions, so only basename() above is backported here
                        exports.basename = function (path, ext) {
                            var f = basename(path);
                            if (ext && f.substr(-1 * ext.length) === ext) {
                                f = f.substr(0, f.length - ext.length);
                            }
                            return f;
                        };

                        exports.extname = function (path) {
                            if (typeof path !== "string") path = path + "";
                            var startDot = -1;
                            var startPart = 0;
                            var end = -1;
                            var matchedSlash = true;
                            // Track the state of characters (if any) we see before our first dot and
                            // after any path separator we find
                            var preDotState = 0;
                            for (var i = path.length - 1; i >= 0; --i) {
                                var code = path.charCodeAt(i);
                                if (code === 47 /*/*/) {
                                    // If we reached a path separator that was not part of a set of path
                                    // separators at the end of the string, stop now
                                    if (!matchedSlash) {
                                        startPart = i + 1;
                                        break;
                                    }
                                    continue;
                                }
                                if (end === -1) {
                                    // We saw the first non-path separator, mark this as the end of our
                                    // extension
                                    matchedSlash = false;
                                    end = i + 1;
                                }
                                if (code === 46 /*.*/) {
                                    // If this is our first dot, mark it as the start of our extension
                                    if (startDot === -1) startDot = i;
                                    else if (preDotState !== 1) preDotState = 1;
                                } else if (startDot !== -1) {
                                    // We saw a non-dot and non-path separator before our dot, so we should
                                    // have a good chance at having a non-empty extension
                                    preDotState = -1;
                                }
                            }

                            if (
                                startDot === -1 ||
                                end === -1 ||
                                // We saw a non-dot character immediately before the dot
                                preDotState === 0 ||
                                // The (right-most) trimmed path component is exactly '..'
                                (preDotState === 1 && startDot === end - 1 && startDot === startPart + 1)
                            ) {
                                return "";
                            }
                            return path.slice(startDot, end);
                        };

                        function filter(xs, f) {
                            if (xs.filter) return xs.filter(f);
                            var res = [];
                            for (var i = 0; i < xs.length; i++) {
                                if (f(xs[i], i, xs)) res.push(xs[i]);
                            }
                            return res;
                        }

                        // String.prototype.substr - negative index don't work in IE8
                        var substr =
                            "ab".substr(-1) === "b"
                                ? function (str, start, len) {
                                      return str.substr(start, len);
                                  }
                                : function (str, start, len) {
                                      if (start < 0) start = str.length + start;
                                      return str.substr(start, len);
                                  };
                    }.call(this));
                }.call(this, require("_process")));
            },
            { _process: 2 },
        ],
        2: [
            function (require, module, exports) {
                // shim for using process in browser
                var process = (module.exports = {});

                // cached from whatever global is present so that test runners that stub it
                // don't break things.  But we need to wrap it in a try catch in case it is
                // wrapped in strict mode code which doesn't define any globals.  It's inside a
                // function because try/catches deoptimize in certain engines.

                var cachedSetTimeout;
                var cachedClearTimeout;

                function defaultSetTimout() {
                    throw new Error("setTimeout has not been defined");
                }
                function defaultClearTimeout() {
                    throw new Error("clearTimeout has not been defined");
                }
                (function () {
                    try {
                        if (typeof setTimeout === "function") {
                            cachedSetTimeout = setTimeout;
                        } else {
                            cachedSetTimeout = defaultSetTimout;
                        }
                    } catch (e) {
                        cachedSetTimeout = defaultSetTimout;
                    }
                    try {
                        if (typeof clearTimeout === "function") {
                            cachedClearTimeout = clearTimeout;
                        } else {
                            cachedClearTimeout = defaultClearTimeout;
                        }
                    } catch (e) {
                        cachedClearTimeout = defaultClearTimeout;
                    }
                })();
                function runTimeout(fun) {
                    if (cachedSetTimeout === setTimeout) {
                        //normal enviroments in sane situations
                        return setTimeout(fun, 0);
                    }
                    // if setTimeout wasn't available but was latter defined
                    if ((cachedSetTimeout === defaultSetTimout || !cachedSetTimeout) && setTimeout) {
                        cachedSetTimeout = setTimeout;
                        return setTimeout(fun, 0);
                    }
                    try {
                        // when when somebody has screwed with setTimeout but no I.E. maddness
                        return cachedSetTimeout(fun, 0);
                    } catch (e) {
                        try {
                            // When we are in I.E. but the script has been evaled so I.E. doesn't trust the global object when called normally
                            return cachedSetTimeout.call(null, fun, 0);
                        } catch (e) {
                            // same as above but when it's a version of I.E. that must have the global object for 'this', hopfully our context correct otherwise it will throw a global error
                            return cachedSetTimeout.call(this, fun, 0);
                        }
                    }
                }
                function runClearTimeout(marker) {
                    if (cachedClearTimeout === clearTimeout) {
                        //normal enviroments in sane situations
                        return clearTimeout(marker);
                    }
                    // if clearTimeout wasn't available but was latter defined
                    if ((cachedClearTimeout === defaultClearTimeout || !cachedClearTimeout) && clearTimeout) {
                        cachedClearTimeout = clearTimeout;
                        return clearTimeout(marker);
                    }
                    try {
                        // when when somebody has screwed with setTimeout but no I.E. maddness
                        return cachedClearTimeout(marker);
                    } catch (e) {
                        try {
                            // When we are in I.E. but the script has been evaled so I.E. doesn't  trust the global object when called normally
                            return cachedClearTimeout.call(null, marker);
                        } catch (e) {
                            // same as above but when it's a version of I.E. that must have the global object for 'this', hopfully our context correct otherwise it will throw a global error.
                            // Some versions of I.E. have different rules for clearTimeout vs setTimeout
                            return cachedClearTimeout.call(this, marker);
                        }
                    }
                }
                var queue = [];
                var draining = false;
                var currentQueue;
                var queueIndex = -1;

                function cleanUpNextTick() {
                    if (!draining || !currentQueue) {
                        return;
                    }
                    draining = false;
                    if (currentQueue.length) {
                        queue = currentQueue.concat(queue);
                    } else {
                        queueIndex = -1;
                    }
                    if (queue.length) {
                        drainQueue();
                    }
                }

                function drainQueue() {
                    if (draining) {
                        return;
                    }
                    var timeout = runTimeout(cleanUpNextTick);
                    draining = true;

                    var len = queue.length;
                    while (len) {
                        currentQueue = queue;
                        queue = [];
                        while (++queueIndex < len) {
                            if (currentQueue) {
                                currentQueue[queueIndex].run();
                            }
                        }
                        queueIndex = -1;
                        len = queue.length;
                    }
                    currentQueue = null;
                    draining = false;
                    runClearTimeout(timeout);
                }

                process.nextTick = function (fun) {
                    var args = new Array(arguments.length - 1);
                    if (arguments.length > 1) {
                        for (var i = 1; i < arguments.length; i++) {
                            args[i - 1] = arguments[i];
                        }
                    }
                    queue.push(new Item(fun, args));
                    if (queue.length === 1 && !draining) {
                        runTimeout(drainQueue);
                    }
                };

                // v8 likes predictible objects
                function Item(fun, array) {
                    this.fun = fun;
                    this.array = array;
                }
                Item.prototype.run = function () {
                    this.fun.apply(null, this.array);
                };
                process.title = "browser";
                process.browser = true;
                process.env = {};
                process.argv = [];
                process.version = ""; // empty string to avoid regexp issues
                process.versions = {};

                function noop() {}

                process.on = noop;
                process.addListener = noop;
                process.once = noop;
                process.off = noop;
                process.removeListener = noop;
                process.removeAllListeners = noop;
                process.emit = noop;
                process.prependListener = noop;
                process.prependOnceListener = noop;

                process.listeners = function (name) {
                    return [];
                };

                process.binding = function (name) {
                    throw new Error("process.binding is not supported");
                };

                process.cwd = function () {
                    return "/";
                };
                process.chdir = function (dir) {
                    throw new Error("process.chdir is not supported");
                };
                process.umask = function () {
                    return 0;
                };
            },
            {},
        ],
        3: [
            function (require, module, exports) {
                const Parser = require("./src/parser").Parser;
                const handlers = require("./src/handlers");

                const defaultParser = new Parser();

                handlers.addDefaults(defaultParser);

                exports.addDefaults = handlers.addDefaults;
                exports.addHandler = (handlerName, handler, options) =>
                    defaultParser.addHandler(handlerName, handler, options);
                exports.parse = (title) => defaultParser.parse(title);
                exports.Parser = Parser;
            },
            { "./src/handlers": 4, "./src/parser": 5 },
        ],
        4: [
            function (require, module, exports) {
                exports.addDefaults = /** @type Parser */ (parser) => {
                    // Year
                    parser.addHandler("year", /(?!^)[([]?((?:19[0-9]|20[012])[0-9])[)\]]?/, { type: "integer" });

                    // Resolution
                    parser.addHandler("resolution", /([0-9]{3,4}[pi])/i, { type: "lowercase" });
                    parser.addHandler("resolution", /(4k)/i, { type: "lowercase" });

                    // Extended
                    parser.addHandler("extended", /EXTENDED/, { type: "boolean" });

                    // Convert
                    parser.addHandler("convert", /CONVERT/, { type: "boolean" });

                    // Hardcoded
                    parser.addHandler("hardcoded", /HC|HARDCODED/, { type: "boolean" });

                    // Proper
                    parser.addHandler("proper", /(?:REAL.)?PROPER/, { type: "boolean" });

                    // Repack
                    parser.addHandler("repack", /REPACK|RERIP/, { type: "boolean" });

                    // Retail
                    parser.addHandler("retail", /\bRetail\b/i, { type: "boolean" });

                    // Remastered
                    parser.addHandler("remastered", /\bRemaster(?:ed)?\b/i, { type: "boolean" });

                    // Unrated
                    parser.addHandler("unrated", /\bunrated|uncensored\b/i, { type: "boolean" });

                    // Region
                    parser.addHandler("region", /R[0-9]/);

                    // Container
                    parser.addHandler("container", /\b(MKV|AVI|MP4)\b/i, { type: "lowercase" });

                    // Source
                    parser.addHandler("source", /\b(?:HD-?)?CAM\b/, { type: "lowercase" });
                    parser.addHandler("source", /\b(?:HD-?)?T(?:ELE)?S(?:YNC)?\b/i, { value: "telesync" });
                    parser.addHandler("source", /\bHD-?Rip\b/i, { type: "lowercase" });
                    parser.addHandler("source", /\bBRRip\b/i, { type: "lowercase" });
                    parser.addHandler("source", /\bBDRip\b/i, { type: "lowercase" });
                    parser.addHandler("source", /\bDVDRip\b/i, { type: "lowercase" });
                    parser.addHandler("source", /\bDVD(?:R[0-9])?\b/i, { value: "dvd" });
                    parser.addHandler("source", /\bDVDscr\b/i, { type: "lowercase" });
                    parser.addHandler("source", /\b(?:HD-?)?TVRip\b/i, { type: "lowercase" });
                    parser.addHandler("source", /\bTC\b/, { type: "lowercase" });
                    parser.addHandler("source", /\bPPVRip\b/i, { type: "lowercase" });
                    parser.addHandler("source", /\bR5\b/i, { type: "lowercase" });
                    parser.addHandler("source", /\bVHSSCR\b/i, { type: "lowercase" });
                    parser.addHandler("source", /\bBluray\b/i, { type: "lowercase" });
                    parser.addHandler("source", /\bWEB-?DL\b/i, { type: "lowercase" });
                    parser.addHandler("source", /\bWEB-?Rip\b/i, { type: "lowercase" });
                    parser.addHandler("source", /\b(?:DL|WEB|BD|BR)MUX\b/i, { type: "lowercase" });
                    parser.addHandler("source", /\b(DivX|XviD)\b/, { type: "lowercase" });
                    parser.addHandler("source", /HDTV/i, { type: "lowercase" });

                    // Codec
                    parser.addHandler("codec", /dvix|mpeg2|divx|xvid|[xh][-. ]?26[45]|avc|hevc/i, {
                        type: "lowercase",
                    });
                    parser.addHandler("codec", ({ result }) => {
                        if (result.codec) {
                            result.codec = result.codec.replace(/[ .-]/, "");
                        }
                    });

                    // Audio
                    parser.addHandler("audio", /MD|MP3|mp3|FLAC|Atmos|DTS(?:-HD)?|TrueHD/, { type: "lowercase" });
                    parser.addHandler("audio", /Dual[- ]Audio/i, { type: "lowercase" });
                    parser.addHandler("audio", /AC-?3(?:\.5\.1)?/i, { value: "ac3" });
                    parser.addHandler("audio", /DD5[. ]?1/i, { value: "dd5.1" });
                    parser.addHandler("audio", /AAC(?:[. ]?2[. ]0)?/, { value: "aac" });

                    // Group
                    parser.addHandler("group", /- ?([^\-. ]+)$/);

                    // Season
                    parser.addHandler("season", /S([0-9]{1,2}) ?E[0-9]{1,2}/i, { type: "integer" });
                    parser.addHandler("season", /([0-9]{1,2})x[0-9]{1,2}/, { type: "integer" });
                    parser.addHandler("season", /(?:Saison|Season)[. _-]?([0-9]{1,2})/i, { type: "integer" });

                    // Episode
                    parser.addHandler("episode", /S[0-9]{1,2} ?E([0-9]{1,2})/i, { type: "integer" });
                    parser.addHandler("episode", /[0-9]{1,2}x([0-9]{1,2})/, { type: "integer" });
                    parser.addHandler("episode", /[ée]p(?:isode)?[. _-]?([0-9]{1,3})/i, { type: "integer" });

                    // Language
                    parser.addHandler("language", /\bRUS\b/i, { type: "lowercase" });
                    parser.addHandler("language", /\bNL\b/, { type: "lowercase" });
                    parser.addHandler("language", /\bFLEMISH\b/, { type: "lowercase" });
                    parser.addHandler("language", /\bGERMAN\b/, { type: "lowercase" });
                    parser.addHandler("language", /\bDUBBED\b/, { type: "lowercase" });
                    parser.addHandler("language", /\b(ITA(?:LIAN)?|iTALiAN)\b/, { value: "ita" });
                    parser.addHandler("language", /\bFR(?:ENCH)?\b/, { type: "lowercase" });
                    parser.addHandler("language", /\bTruefrench|VF(?:[FI])\b/i, { type: "lowercase" });
                    parser.addHandler("language", /\bVOST(?:(?:F(?:R)?)|A)?|SUBFRENCH\b/i, { type: "lowercase" });
                    parser.addHandler("language", /\bMULTi(?:Lang|-VF2)?\b/i, { type: "lowercase" });
                };
            },
            {},
        ],
        5: [
            function (require, module, exports) {
                function extendOptions(options) {
                    options = options || {};

                    const defaultOptions = {
                        skipIfAlreadyFound: true,
                        type: "string",
                    };

                    options.skipIfAlreadyFound = options.skipIfAlreadyFound || defaultOptions.skipIfAlreadyFound;
                    options.type = options.type || defaultOptions.type;

                    return options;
                }

                function createHandlerFromRegExp(name, regExp, options) {
                    let transformer;

                    if (!options.type) {
                        transformer = (input) => input;
                    } else if (options.type.toLowerCase() === "lowercase") {
                        transformer = (input) => input.toLowerCase();
                    } else if (options.type.toLowerCase().slice(0, 4) === "bool") {
                        transformer = () => true;
                    } else if (options.type.toLowerCase().slice(0, 3) === "int") {
                        transformer = (input) => parseInt(input, 10);
                    } else {
                        transformer = (input) => input;
                    }

                    function handler({ title, result }) {
                        if (result[name] && options.skipIfAlreadyFound) {
                            return null;
                        }

                        const match = title.match(regExp);
                        const [rawMatch, cleanMatch] = match || [];

                        if (rawMatch) {
                            result[name] = options.value || transformer(cleanMatch || rawMatch);
                            return match.index;
                        }

                        return null;
                    }

                    handler.handlerName = name;

                    return handler;
                }

                function cleanTitle(rawTitle) {
                    let cleanedTitle = rawTitle;

                    if (cleanedTitle.indexOf(" ") === -1 && cleanedTitle.indexOf(".") !== -1) {
                        cleanedTitle = cleanedTitle.replace(/\./g, " ");
                    }

                    cleanedTitle = cleanedTitle.replace(/_/g, " ");
                    cleanedTitle = cleanedTitle.replace(/([(_]|- )$/, "").trim();

                    return cleanedTitle;
                }

                class Parser {
                    constructor() {
                        this.handlers = [];
                    }

                    addHandler(handlerName, handler, options) {
                        if (typeof handler === "undefined" && typeof handlerName === "function") {
                            // If no name is provided and a function handler is directly given
                            handler = handlerName;
                            handler.handlerName = "unknown";
                        } else if (typeof handlerName === "string" && handler instanceof RegExp) {
                            // If the handler provided is a regular expression
                            options = extendOptions(options);
                            handler = createHandlerFromRegExp(handlerName, handler, options);
                        } else if (typeof handler === "function") {
                            // If the handler is a function
                            handler.handlerName = handlerName;
                        } else {
                            // If the handler is neither a function nor a regular expression, throw an error
                            throw new Error(
                                `Handler for ${handlerName} should be a RegExp or a function. Got: ${typeof handler}`
                            );
                        }

                        this.handlers.push(handler);
                    }

                    parse(title) {
                        const result = {};
                        let endOfTitle = title.length;

                        for (const handler of this.handlers) {
                            const matchIndex = handler({ title, result });

                            if (matchIndex && matchIndex < endOfTitle) {
                                endOfTitle = matchIndex;
                            }
                        }

                        result.title = cleanTitle(title.slice(0, endOfTitle));

                        return result;
                    }
                }

                exports.Parser = Parser;
            },
            {},
        ],
        6: [
            function (require, module, exports) {
                const _parser = require("parse-torrent-title");
                // seems to work better than parse-torrent-name?

                window.parser = _parser.parse;

                window.path = require("path");
                // browserify parse-torrent-name.js -o browserified.js
            },
            { "parse-torrent-title": 3, path: 1 },
        ],
    },
    {},
    [6]
);
