<script>
    import { metastore, progressStore, objectFilestore } from "../js/stores";
    import { get_all_files_as_object } from "../js/premiumize";
    import { parse_filename, plural, round } from "../js/helpers";
    import { find_meta_info, new_or_existing_show, new_or_existing_season } from "../js/moviedb";
    import { onDestroy } from "svelte";
    import Spinner from "../components/Spinner.svelte";

    // movies that are there by mistake and should be in other.
    const BLACKLIST = ["s8mqaXT_VcgLG2Nv0EQDTg"];
    // movies that are in other by mistake
    const WHITELIST = ["hZvhFKvMKz3ehIO-PyVMgg"];

    export let show_id, movie_id, title, big; // big = tv mode?

    title = "Catalog";

    function goto_show(id) {
        show_id = id;
        window.history.pushState(id, id, `?show_id=${show_id}`);
    }

    function goto_movie(id) {
        movie_id = id;
        window.history.pushState(id, id, `?movie_id=${movie_id}`);
    }

    let shows = {}; // temporary serie holder
    let movies = [];
    let _movies = {}; // movies but keyed by id
    let tv = []; // final holder
    let other = [];
    let continue_watching = [];

    export async function get_files(cache = true) {
        // if cache is empty, listall from server
        if (cache && Object.keys($objectFilestore).length) {
            return true;
        }
        const obj = await get_all_files_as_object();
        objectFilestore.update((_) => obj);
        return true;
    }

    function extract_info(file) {
        // parse movie file name and categorize to movie/tv/other
        // if movie, load meta data
        // if show, group with other episodes
        const name = file["name"];
        const file_info = parse_filename(name);
        const all_info = { ...file, ...file_info };

        if (file_info["season"] && file_info["episode"]) {
            // is tv show

            let show = new_or_existing_show(all_info["title"], shows);
            let season = new_or_existing_season(show, all_info["season"]);
            season.episodes[all_info["episode"]] = all_info;
        } else if ((file_info["year"] && !BLACKLIST.includes(all_info["id"])) || WHITELIST.includes(all_info["id"])) {
            // is movie
            all_info["type"] = "movie";
            load_meta_movie_info(all_info);

            movies = [all_info, ...movies];
            _movies[all_info["id"]] = all_info;
        } else {
            // trash
            all_info["type"] = "other";
            other = [all_info, ...other];
        }
        return "";
    }

    async function load_meta_movie_info(file_info) {
        const id = file_info["id"];
        if ($metastore[id]) {
            // cache for movie exists
            return $metastore[id];
        }
        // else
        const meta = await find_meta_info(file_info["title"], file_info["type"], file_info["year"]);
        $metastore[id] = meta;
        return meta;
    }

    async function load_meta_show_info() {
        for (const [show_name_mixed_case, show_instance] of Object.entries(shows)) {
            let meta_show_instance;
            const show_name = show_name_mixed_case.toLowerCase();
            if ($metastore[show_name]) {
                // cache for show exists
                meta_show_instance = $metastore[show_name];
            } else {
                // load + cache
                // meta info from tmdb
                show_instance.meta = await find_meta_info(show_name, "tv");
                // show name as title
                show_instance.title = show_instance.name; // show_name
                // first episode to show quality/resolution/year etc.
                show_instance.episode = show_instance.first_available_episode();
                meta_show_instance = $metastore[show_name] = show_instance;
            }
            // meta_show_instance['instance'] = show_instance;
            tv = [...tv, meta_show_instance];
        }
        return tv;
    }

    const unsub_obj = objectFilestore.subscribe((data) => {
        for (const file of Object.values(data)) {
            if (file["mime_type"] && file["mime_type"].startsWith("video/")) {
                extract_info(file);
            }
        }
        load_meta_show_info();
    });

    const unsub_prog = progressStore.subscribe((data) => {
        for (const [id, progress] of Object.entries(data)) {
            if (!progress.done && _movies[id]) {
                // 'continue watching' movies:
                const percentage = round((progress["last_seen"] / progress["duration"]) * 100, 1);
                if (percentage < 1) {
                    // not enough, ignore
                    continue;
                }

                continue_watching = [
                    {
                        id: id,
                        ..._movies[id],

                        progress_percentage: percentage,
                    },
                    ...continue_watching,
                ];
            }
        }
    });

    onDestroy(unsub_obj);
    onDestroy(unsub_prog);

    // function do_filter() {
    //     // todo: create actual filters
    //     let newmovies = [];
    //     for (const i in movies) {
    //         const movie = movies[i];
    //         const movie_meta = $metastore[movie['id']];
    //         // example filters:
    //         // if(movie['year'] < 2017){
    //         //     continue
    //         // }
    //
    //         // if(movie['resolution'] !== '1080p'){
    //         //     continue
    //         // }
    //
    //         if(movie['year'] > 2010) {
    //             continue;
    //         }
    //
    //         if(!movie_meta || !movie_meta['backdrop_path']) {
    //             continue;
    //         }
    //
    //         newmovies.push(movie);
    //
    //     }
    //     movies = newmovies;
    // }

    if (big) {
        function scroll_to(target) {
            jQuery("html, body").animate(
                {
                    scrollTop: target.offset().top - 100,
                },
                300
            );
        }

        // enable keyboard navigation (or TODO: simulated keyboard)
        let index = -1;
        let category_index = 0;
        jQuery((_) => {
            let covers = jQuery(".tvmodecontainer").eq(category_index).find(".card__cover");
            let once = true; // make sure no reload on video page
            document.addEventListener("keydown", (event) => {
                let prev = covers.eq(index);
                if (event.key.startsWith("Arrow") || event.key.startsWith("Page")) {
                    event.preventDefault();

                    if (event.key === "ArrowRight") index++;
                    else if (event.key === "ArrowLeft") index--;
                    else if (event.key === "ArrowDown") index >= 0 ? (index += 6) : (index = 0);
                    else if (event.key === "ArrowUp") index -= 6;
                    if (event.key === "PageDown" || index >= covers.length) {
                        index = 0;
                        category_index++;
                        covers = jQuery(".tvmodecontainer").eq(category_index).find(".card__cover");
                    } else if (event.key === "PageUp" || index < 0) {
                        category_index--;
                        if (category_index < 0) category_index = 0;
                        covers = jQuery(".tvmodecontainer").eq(category_index).find(".card__cover");
                        // if pageup: start at top, else previous
                        index = event.key === "PageUp" ? 0 : covers.length - 1;
                    }
                } else if (event.key === "Enter") {
                    if (index >= 0 && once) {
                        covers.eq(index).find("a")[0].click();
                        once = false;
                    }
                    return;
                } else if (!isNaN(event.key)) {
                    index = event.key - 1;
                } else {
                    return;
                }

                prev.css("border", "none");
                let curr = covers.eq(index);
                curr.css("border", "10px solid red");
                scroll_to(curr);
            });
        });
    }
</script>

<!-- filter (disabled) -->
<div class="filter">
    <div class="container">
        <div class="row">
            <div class="col-12" />
        </div>
    </div>
</div>
<!-- end filter -->

{#await get_files()}
    <Spinner />
{:then data}
    <!-- tv shows -->
    <section class="section section--bg" data-bg="img/section/section.jpg">
        <div class="container{big ? '-fluid tvmodecontainer' : ''}">
            <!-- section title -->
            <div class="col-12">
                <h2 class="section__title" data-toggle="collapse" href="#tv-row">TV Shows</h2>
            </div>
            <!-- end section title -->
            <div class="row collapse show" id="tv-row">
                {#each tv as show}
                    {#if show.title && show.meta}
                        <!-- card -->
                        <div class="col-6 col-sm-4 col-lg-3 col-xl-2">
                            <div class="card">
                                <div class="card__cover" on:click|preventDefault={() => goto_show(show.name)}>
                                    <img
                                        src="https://image.tmdb.org/t/p/w200/{show.meta.poster_path}"
                                        alt="{show.title} poster"
                                    />
                                    <a
                                        href="/?show_id={show.name}"
                                        on:click|preventDefault={() => goto_show(show.name)}
                                        class="card__play"
                                    >
                                        <i class="icon ion-ios-play" />
                                    </a>
                                </div>
                                <div class="card__content">
                                    <h3 class="card__title">
                                        <a
                                            href="/?show_id={show.name}"
                                            on:click|preventDefault={() => goto_show(show.name)}>{show.title}</a
                                        >
                                    </h3>
                                    <span class="card__category">
                                        <a>{show.meta.first_air_date.split("-")[0]}</a>
                                        <a>{show.meta.origin_country[0]}</a>
                                    </span>
                                    <span class="card__rate"
                                        ><i class="icon ion-ios-star" />
                                        {show.meta.vote_average}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!-- end card -->
                    {/if}
                {/each}
            </div>
        </div>
    </section>
    <!-- end tv shows -->

    {#if continue_watching.length}
        <!-- continue watching -->
        <div class="catalog">
            <div class="container{big ? '-fluid tvmodecontainer' : ''}">
                <div class="row">
                    <!-- section title -->
                    <div class="col-12">
                        <h2 class="section__title" data-toggle="collapse" href="#continue-watching-row">
                            Continue Watching
                        </h2>
                    </div>
                    <!-- end section title -->
                </div>
                <div class="row collapse show" id="continue-watching-row">
                    {#each continue_watching as movie}
                        <!-- card -->
                        <div class="col-6 col-sm-4 col-lg-3 col-xl-2" title={movie.name}>
                            <div class="card">
                                <div class="card__cover" on:click|preventDefault={() => goto_movie(movie.id)}>
                                    {#if $metastore[movie.id]}
                                        <img
                                            src="https://image.tmdb.org/t/p/w200/{$metastore[movie.id].poster_path}"
                                            alt=""
                                        />
                                    {:else}
                                        <img
                                            src="https://media.comicbook.com/files/img/default-movie.png"
                                            alt="no meta info available"
                                        />
                                    {/if}
                                    <a
                                        href="/?movie_id={movie.id}"
                                        on:click|preventDefault={() => goto_movie(movie.id)}
                                        class="card__play"
                                    >
                                        <i class="icon ion-ios-play" />
                                    </a>
                                </div>
                                <div class="card__content">
                                    <h3 class="card__title">
                                        <a
                                            href="/?movie_id={movie.id}"
                                            on:click|preventDefault={() => goto_movie(movie.id)}>{movie.title}</a
                                        >
                                    </h3>
                                    <span class="card__category">
                                        <a>{movie.year}, {movie.resolution}</a>
                                    </span>
                                    <span class="card__rate"
                                        ><i class="icon ion-ios-star" />
                                        {($metastore[movie.id] || {}).vote_average || "?"}
                                    </span>
                                    <span class="card__progress card__rate">
                                        <i class="icon ion-ios-timer" />
                                        {movie["progress_percentage"]}%
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!-- end card -->
                    {/each}
                </div>
            </div>
        </div>
        <!-- end continue watching -->
    {/if}

    <!-- catalog -->
    <div class="catalog">
        <div class="container{big ? '-fluid tvmodecontainer' : ''}">
            <div class="row">
                <!-- section title -->
                <div class="col-12">
                    <h2 class="section__title" data-toggle="collapse" href="#movie-row">All Movies</h2>
                </div>
                <!-- end section title -->
            </div>
            <div class="row collapse show" id="movie-row">
                {#each movies as movie}
                    <!-- card -->
                    <div class="col-6 col-sm-4 col-lg-3 col-xl-2" title={movie.name}>
                        <div class="card">
                            <div class="card__cover" on:click|preventDefault={() => goto_movie(movie.id)}>
                                {#if $metastore[movie.id]}
                                    <img
                                        class="seen"
                                        class:seen={$progressStore[movie.id] && $progressStore[movie.id]["done"]}
                                        src="https://image.tmdb.org/t/p/w200/{$metastore[movie.id].poster_path}"
                                        alt=""
                                    />
                                {:else}
                                    <img
                                        src="https://media.comicbook.com/files/img/default-movie.png"
                                        alt="no meta info available"
                                    />
                                {/if}
                                <a
                                    href="/?movie_id={movie.id}"
                                    on:click|preventDefault={() => goto_movie(movie.id)}
                                    class="card__play"
                                >
                                    <i class="icon ion-ios-play" />
                                </a>
                            </div>
                            <div class="card__content">
                                <h3 class="card__title">
                                    <a href="/?movie_id={movie.id}" on:click|preventDefault={() => goto_movie(movie.id)}
                                        >{movie.title}</a
                                    >
                                </h3>
                                <span class="card__category">
                                    <a>{movie.year}, {movie.resolution}</a>
                                </span>
                                <span class="card__rate"
                                    ><i class="icon ion-ios-star" />
                                    {($metastore[movie.id] || {}).vote_average || "?"}
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- end card -->
                {/each}
            </div>
        </div>
    </div>
    <!-- end catalog -->

    <!-- other files -->
    <section class="section section--bg">
        <div class="container{big ? '-fluid tvmodecontainer' : ''}">
            <div class="row">
                <!-- section title -->
                <div class="col-12">
                    <a
                        data-toggle="collapse"
                        href="#hidden_files"
                        role="button"
                        aria-expanded="false"
                        aria-controls="hidden_files"
                    >
                        <h2 class="section__title">Show Unclassified Files</h2>
                    </a>
                </div>
                <!-- end section title -->

                <div class="row collapse" id="hidden_files">
                    {#each other as other_file}
                        {#if other_file.title}
                            <!-- card -->
                            <div class="col-6 col-sm-4 col-lg-3 col-xl-2" title={other_file.name}>
                                <div class="card">
                                    <div class="card__cover">
                                        <img
                                            src="https://media.comicbook.com/files/img/default-movie.png"
                                            alt="{other_file.title} poster"
                                        />
                                        <a
                                            href="#"
                                            on:click|preventDefault={() => goto_movie(other_file.id)}
                                            class="card__play"
                                        >
                                            <i class="icon ion-ios-play" />
                                        </a>
                                    </div>
                                    <div class="card__content">
                                        <h3 class="card__title">
                                            <a href="#" on:click|preventDefault={() => goto_movie(other_file.id)}
                                                >{other_file.title}</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <!-- end card -->
                        {/if}
                    {/each}
                </div>
            </div>
        </div>
    </section>
    <!-- end other files -->
{/await}

<style>
    img.seen {
        filter: brightness(25%);
    }

    .tvmodecontainer {
        padding-left: 100px;
        padding-right: 100px;
    }

    .card__progress {
        color: white;
        font-size: 15px;
        float: right;
    }
</style>
