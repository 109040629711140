<script>
    import Generic from "../components/Generic.svelte";

    export const location = "/404";

    function back() {
        window.history.back();
    }
</script>

<Generic show_footer={false} show_title={false}>
    <div
        class="page-404 section--bg"
        data-bg="img/section/section.jpg"
        style="background: url(&quot;img/section/section.jpg&quot;) center center / cover no-repeat;"
    >
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="page-404__wrap">
                        <div class="page-404__content">
                            <h1 class="page-404__title">404</h1>
                            <p class="page-404__text">The page you are looking for not available!</p>
                            <a href="#" on:click|preventDefault={back} class="page-404__btn">go back</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</Generic>
