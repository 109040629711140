import * as _theMovieDb from "themoviedb-javascript-library";
import * as config from "../../settings";

const theMovieDb = _theMovieDb.default;

theMovieDb.common.api_key = config.tmdb;
theMovieDb.common.base_uri = "https://api.themoviedb.org/3/";
theMovieDb.common.images_uri = "https://image.tmdb.org/t/p/";

function extract_result(data) {
    try {
        return JSON.parse(data)["results"][0];
    } catch (e) {
        return {};
    }
}

export async function find_meta_info(name, type, year = null) {
    const data = { query: name };
    if (year) {
        data["year"] = year;
    }
    const method = type === "tv" ? theMovieDb.search.getTv : theMovieDb.search.getMovie;
    return new Promise(function (resolve, reject) {
        if (!name) {
            resolve([]);
        }
        method(data, (d) => resolve(extract_result(d)), reject);
    });
}

// class helpers
// season holder
class Season {
    constructor() {
        this.episodes = {};
    }
}

// show holder
class Show {
    constructor(original_name) {
        this.name = original_name;
        this.seasons = {};
    }

    first_available_season() {
        return this.seasons[Object.keys(this.seasons)[0]];
    }

    first_available_episode() {
        const season = this.first_available_season();
        return season.episodes[Object.keys(season.episodes)[0]];
    }
}

// give back an instance for show with name
export function new_or_existing_show(name, shows) {
    const lower_name = name.toLowerCase();
    return shows[lower_name] || (shows[lower_name] = new Show(name));
}

// give back an instance of season for season no of show
export function new_or_existing_season(show, no) {
    return show["seasons"][no] || (show["seasons"][no] = new Season());
}
