<script>
    // this page is very similar to Show.svelte but with minor changes for the slightly different structure
    import { metastore } from "../js/stores";
    import { parse_filename, filename_simple } from "../js/helpers";
    import { get_details } from "../js/premiumize";
    import VideoComponent from "../components/VideoComponent.svelte";

    export let id, big;
    let source;
    let sub_src;

    let data = {};
    export let title = "Video";
    export let chromecast;

    function set_details(id) {
        get_details(id).then((_dat) => {
            // document.querySelector('#my-video').setAttribute('src', _dat['stream_link']);
            source = _dat["stream_link"] || _dat["link"];

            sub_src = "/api/subtitles?query=" + _dat["name"];

            let file = (_dat["file"] = parse_filename(_dat.name));
            _dat["meta"] = { ...$metastore[_dat.id], transcode_status: _dat["transcode_status"] };
            title = file.title;
            data = _dat;
        });
    }

    set_details(id);

    metastore.subscribe((meta) => {
        data["meta"] = meta[data.id];
    });
</script>

<!-- details -->
<section class="section details" id="details-page">
    <!-- details background -->
    {#if data.meta}
        <div
            id="details-image"
            class="details__bg"
            data-bg="https://image.tmdb.org/t/p/w1280/{data.meta.backdrop_path}"
        />
    {/if}
    <!-- end details background -->

    <!-- details content -->
    <div class="container">
        <div class="row">
            <!-- title -->
            <div class="col-12">
                <h1 class="details__title">
                    <a target="_blank" href="https://www.premiumize.me/file?id={id}">
                        {#if data.file}
                            {data.file.title}
                            {#if data.file.year}
                                ({data.file.year})
                            {/if}
                        {:else}
                            {data.name}
                        {/if}
                    </a>
                </h1>
            </div>
            <!-- end title -->

            <!-- content -->
            <div class="col-12 col-xl-6">
                <div class="card card--details">
                    <div class="row">
                        <!-- card cover -->
                        <div class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-5">
                            <div class="card__cover">
                                {#if data.meta}
                                    <img src="https://image.tmdb.org/t/p/w500/{data.meta.poster_path}" alt="" />
                                {:else}
                                    <img
                                        src="https://media.comicbook.com/files/img/default-movie.png"
                                        alt="no image available"
                                    />
                                {/if}
                            </div>
                        </div>
                        <!-- end card cover -->

                        <!-- card content -->
                        <div class="col-12 col-sm-8 col-md-8 col-lg-9 col-xl-7">
                            <div class="card__content">
                                <div class="card__wrap">
                                    {#if data.meta}
                                        <span class="card__rate"
                                            ><i class="icon ion-ios-star" />
                                            {data.meta.vote_average}
                                        </span>
                                    {/if}

                                    <ul class="card__list">
                                        {#if data.file}
                                            <li>{data.file.resolution}</li>
                                            <li>{data.file.quality}</li>
                                        {/if}
                                    </ul>
                                </div>

                                <ul class="card__meta">
                                    {#if data.meta}
                                        <li><span>Release year:</span>{data.meta.release_date.split("-")[0]}</li>
                                    {/if}
                                </ul>

                                {#if data.meta}
                                    <div class="card__description card__description--details">
                                        {data.meta.overview}
                                    </div>
                                {/if}
                            </div>
                        </div>
                        <!-- end card content -->
                    </div>
                </div>
            </div>
            <!-- end content -->

            <!-- player -->
            <div class="col-12 col-xl-6">
                <VideoComponent viewing_id={id} {source} metadata={data.meta} {sub_src} {chromecast} bind:big />
            </div>
            <!-- end player -->
        </div>
    </div>
    <!-- end details content -->
</section>

<!-- end details -->
<style>
    #details-page {
        margin-top: 0;
    }

    #details-image {
        background-position: top !important;
    }

    h1 a {
        color: white;
    }
</style>
