<script>
    export let source;
    export let metadata;
</script>

<div>
    <form method="POST" action="/api/stream">
        <input name="source" bind:value={source} type="hidden" />
        <button id="stream" type="submit" class="form__btn" title="Transcode: {metadata?.['transcode_status']}"
            >Play with VLC</button
        >
    </form>
</div>
