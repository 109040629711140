<script>
    import RequireSignin from "../components/RequireSignin.svelte";
    import Spinner from "../components/Spinner.svelte";
    import TraktDataDisplay from "../components/TraktDataDisplay.svelte";
    import { trakt } from "../js/trakt";
</script>

<RequireSignin title="Trending">
    <div class="filter">
        <!-- empty filler -->
        <div class="container">
            <div class="row">
                <div class="col-12" />
            </div>
        </div>
    </div>

    {#await trakt("movies/trending", { extended: "full", limit: 100 })}
        <Spinner />
    {:then data}
        <div class="catalog">
            <div class="container">
                <div class="row row--grid">
                    {#each data as { movie }}
                        <TraktDataDisplay {movie} />
                    {/each}
                </div>
            </div>
        </div>
    {/await}
</RequireSignin>
