<script>
    import "@pwabuilder/pwainstall";
</script>

<!-- footer -->
<footer class="footer">
    <div class="container">
        <div class="row">
            <!-- footer copyright -->
            <div class="col-12">
                <div class="footer__copyright" style="border:0;padding:0;margin:0;">
                    <h3>
                        <a target="_blank" href="https://trialandsuccess.nl" style="color:white;">Trial and Success</a>
                    </h3>
                    <ul style="color: rgba(255,255,255,0.5)">
                        <li>Still in Beta</li>
                        <li>No Guarantees</li>
                    </ul>
                </div>
            </div>
            <!-- end footer copyright -->
        </div>
    </div>
</footer>
<!-- end footer -->
