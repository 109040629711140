<script>
    import * as j from "jquery";
    import Footer from "../components/Footer.svelte";
    import Header from "../components/Header.svelte";
    import Title from "../components/Title.svelte";

    window.jQuery = window.$ = j.default;
    export let title;
    export let show_header = true;
    export let show_title = true;
    export let show_footer = true;
</script>

<svelte:head>
    <!--<link rel="stylesheet" href="https://fa.su6.nl" />-->
    <link rel="stylesheet" href="/css/fa/all.min.css" />
</svelte:head>
<main>
    {#if show_header === true}
        <Header />
    {/if}
    {#if show_title === true}
        <Title {title} />
    {/if}
    <slot />
    {#if show_footer}
        <Footer />
    {/if}
    <!-- JS -->
    <script src="/js/jquery-3.3.1.min.js"></script>
    <script src="/js/main.js"></script>
</main>
