// premiumize

import * as config from "../../settings";

const API_KEY = config.premiumize;
const ENDPOINT = "https://www.premiumize.me/api/";

export function objectToQueryString(obj) {
    return Object.keys(obj)
        .map((key) => key + "=" + obj[key])
        .join("&");
}

export async function request_GET(path, params = {}) {
    const querystring = objectToQueryString({ apikey: API_KEY, ...params });
    const response = await fetch(`${ENDPOINT}${path}?${querystring}`);
    return response.json();
}

export async function request_POST(path, data) {
    const querystring = objectToQueryString({ apikey: API_KEY });
    const response = await fetch(`${ENDPOINT}${path}?${querystring}`, {
        method: "POST",
        body: data,
        // 'body': JSON.stringify(data)
    });
    return response.json();
}

export async function create_transfer(magnet) {
    // return {
    //     id: "ZCnCrcXUMOaGAj0v2VvArg",
    //     name: "Extraction (2020) [1080p] [WEBRip] [5.1] [YTS] [YIFY]",
    //     status: "success",
    //     type: "savetocloud",
    // };
    const data = new FormData();
    data.append("src", magnet);
    return await request_POST("transfer/create", data);
}

export async function get_details(id, cache = true) {
    return await request_GET("item/details", { id });
}

export async function get_all_files_as_object() {
    const items = await request_GET("item/listall");
    const obj = {};
    for (let item of items["files"]) {
        obj[item.id] = item;
    }
    return obj;
}
