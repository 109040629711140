<script>
    import RequireSignin from "../components/RequireSignin.svelte";
    import Show from "./Show.svelte";

    export let id;
    let title;
</script>

<RequireSignin {title}>
    <Show {id} show_id={id} bind:title />
</RequireSignin>
