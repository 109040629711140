<script>
    import Spinner from "./Spinner.svelte";
    import { metastore, objectFilestore } from "../js/stores";
    import { humanize, round, str, upper } from "../js/helpers";
    import { objectToQueryString } from "../js/premiumize";
    import { find_meta_info } from "../js/moviedb";

    export let movie;

    let loading;

    async function check_if_in_premiumize(movie) {
        const tmdb = movie.ids.tmdb;

        const matches = Object.entries($metastore).filter(
            ([idx, m]) => m && m.id === tmdb && !str(idx).startsWith("tmdb_")
        );

        if (matches.length) {
            const [match_id, _] = matches[0];

            const premiumize_item = $objectFilestore[match_id];

            if (premiumize_item) {
                // movie exists in premiumize!
                window.location.href = "/?movie_id=" + match_id;
                return true;
            }
        }

        // else, no match.
        // find where to watch with juswatch api (mostly netflix (nfx)):
    }

    const allowed_providers = ["nfx"];

    async function check_if_on_netflix(movie) {
        let resp;
        const query = {
            film: movie.title,
            year: movie.year,
        };

        resp = await fetch("/api/justwatch?" + objectToQueryString(query));
        resp = await resp.json();

        const in_allowed_provider = resp.offers.filter((off) => allowed_providers.includes(off.package_short_name));

        if (in_allowed_provider.length) {
            // ga op netflix kijken dan
            const _urls = in_allowed_provider[0].urls;

            window.location.href = _urls.deeplink_web || _urls.standard_web;
            return true;
        }
    }

    async function try_to_download(movie) {
        console.log("try to torrent", movie);

        window.location.href = "/search?" + objectToQueryString({ s: `${movie.title} ${movie.year || ""} 1080p` });
    }

    async function try_to_watch(movie) {
        loading = movie.ids.tmdb;

        (await check_if_in_premiumize(movie)) || (await check_if_on_netflix(movie)) || (await try_to_download(movie));

        loading = null;
    }

    // copied from Catalog.svelte:
    export async function find_metadata_for(title, year, id = null, type = "movie") {
        if ($metastore[id]) {
            // cache for movie exists
            return $metastore[id];
        }

        const meta = await find_meta_info(title, type, year);
        if (id) {
            $metastore[id] = meta;
        }
        return meta;
    }
</script>

<!-- card -->
<div class="col-6 col-sm-12 col-lg-6">
    {#if loading === movie.ids.tmdb}
        <div class="spinner-holder">
            <Spinner />
        </div>
    {/if}

    <div class="card card--list" class:opaque={loading === movie.ids.tmdb}>
        <div class="row">
            <div class="col-12 col-sm-4">
                <div class="card__cover" on:click|preventDefault={(_) => try_to_watch(movie)}>
                    {#await find_metadata_for(movie.title, movie.year, "tmdb_" + movie.ids.tmdb)}
                        <img src="img/popcorn.png" alt="No Image Available for {movie.title}" />
                    {:then data}
                        {#if data && data.poster_path}
                            <img src="https://image.tmdb.org/t/p/w200/{data.poster_path}" alt={movie.title} />
                        {:else}
                            <img src="img/popcorn.png" alt="No Image Available for {movie.title}" />
                        {/if}
                    {/await}
                    <a href="#" class="card__play">
                        <i class="icon ion-ios-play" />
                    </a>
                </div>
            </div>

            <div class="col-12 col-sm-8">
                <div class="card__content">
                    <h3 class="card__title" on:click|preventDefault={(_) => try_to_watch(movie)}>
                        <a href="#">{movie.title}</a>
                    </h3>
                    <span class="card__category">
                        <a href="#">{movie.year}</a>
                        <a href="#">{upper(movie.country)}</a>
                    </span>

                    <div class="card__wrap">
                        <span class="card__rate"><i class="icon ion-ios-star" />{round(movie.rating, 1)}</span>

                        <ul class="card__list">
                            <li>{movie.certification || ""}</li>
                            <li>{humanize(movie.runtime || 0)}</li>
                        </ul>
                    </div>

                    <div class="card__description">
                        <b class="tagline">{movie.tagline}</b>
                        <p>{movie.overview}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- end card -->
<style>
    .tagline {
        color: white;
    }

    .opaque {
        opacity: 0.3;
        pointer-events: none;
    }

    .spinner-holder {
        position: absolute;
        z-index: 100;
        /* margin: auto; */
        width: 100%;
        top: 25%;
    }
</style>
