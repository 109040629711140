<script>
    import Plyr from "plyr";
    import { onDestroy, onMount } from "svelte";
    import Spinner from "./Spinner.svelte";
    import { progressStore } from "../js/stores";
    import Cast from "./Cast.svelte";
    import Controls from "./Controls.svelte";
    import StreamButton from "./StreamButton.svelte";

    export let source;
    export let sub_src;
    export let metadata;
    export let id = "my-video";
    export let viewing_id;
    export let big;
    export let chromecast;

    let plyr;
    let captions = [];

    function sleep(sec) {
        return new Promise((resolve) => setTimeout(resolve, sec * 1000));
    }

    async function get_additional_captions(sub_src) {
        if (!sub_src) {
            return;
        }

        const url = sub_src + "&list=1";
        const resp = await (await fetch(url)).json();

        for (let { name, source } of resp) {
            captions = [...captions, [name, source]];
        }
    }

    onMount(() => {
        plyr = new Plyr("#my-video", {
            clickToPlay: true,
            settings: ["captions"],
            storage: { enabled: false },

            captions: {
                active: true,
                language: "en",
                update: true,
            },
        });
    });

    $: get_additional_captions(sub_src);

    function toggle_theater_mode() {
        jQuery("#videoholder").parent().toggleClass("col-xl-6 col-xl-12");
    }

    if (big) {
        jQuery((_) => {
            let playing = false;

            document.addEventListener("keydown", (event) => {
                if (event.target.tagName === "INPUT") {
                    // don't do these actions when input is active,
                    // e.g. the user is searching
                    return;
                }

                switch (event.key) {
                    case "Enter":
                    case "f": {
                        event.preventDefault();
                        if (!playing) {
                            plyr.fullscreen.enter();
                            plyr.play();
                            playing = true;
                        } else {
                            plyr.fullscreen.exit();
                            plyr.pause();
                            playing = false;
                        }
                        break;
                    }
                    case "r": {
                        window.location.href = "/";
                        return false;
                    }
                    case " ": {
                        event.preventDefault();
                        plyr.togglePlay();
                        break;
                    }
                    case "c": {
                        plyr.toggleCaptions();
                        break;
                    }
                    case "t": {
                        toggle_theater_mode();
                        break;
                    }
                    default: {
                        // todo: more controls
                        break;
                    }
                }
            });
        });
    }

    let limiter = 0;

    let first = true;
    const unsubscribe = progressStore.subscribe((_data) => {
        if (!first) return;
        // only do this when the video first loads
        const vid = document.getElementById("my-video");

        if ($progressStore[viewing_id] && vid) {
            // yes! both exist, set progress
            vid.currentTime = $progressStore[viewing_id]["last_seen"];
            first = false;
        }
    });

    onDestroy(unsubscribe);

    jQuery((_) => {
        // on ready
        const video_element = jQuery(`#${id}`)
            .on("timeupdate", (ev) => {
                // on time update
                // only save every n times (so we dont spam the browser/server)
                limiter++;
                if (limiter === 40) {
                    // 40 = about every 10 seconds
                    const duration = video_element[0].duration;
                    const timestamp = ev.target.currentTime;
                    const progress = timestamp / duration;
                    const done = progress > 0.9;

                    // progressStore
                    progressStore.update((c) => {
                        c[viewing_id] = {
                            last_seen: timestamp,
                            duration: duration,
                            done,
                        };
                        return c;
                    });

                    // console.log(viewing_id, timestamp, duration, ev.target.currentTime, done);
                    limiter = 0;
                }
            })
            .on("loadeddata", (_) => {
                jQuery("#spinnerholder").hide();
                video_element.show();

                // ensure progress is set correctly:
                if (first) {
                    // firebase was faster than video, setting now:
                    if ($progressStore[viewing_id]) {
                        video_element[0].currentTime = $progressStore[viewing_id]["last_seen"];
                    }
                }
            });

        // if watched on /c (chromecast mode): auto full screen etc.
        if (chromecast) {
            // plyr.fullscreen.enter(); // <- not allowed without user interaction
            toggle_theater_mode();
            setTimeout((_) => {
                video_element[0].scrollIntoView();
                plyr.play();
            }, 1000);

            // make buttons bigger:
            const svg = jQuery(".plyr__controls svg");
            const size = "50px";
            svg.css("height", size);
            svg.css("width", size);

            // play/pause clicking on non-buttons:
            jQuery(".plyr__poster").on("click", plyr.togglePlay);
        }
    });
</script>

<div class="tip">
    {#if metadata && metadata["transcode_status"] === "error"}
        <i class="warning"> Video was not fully transcoded, try playing in VLC if you have no sound or poor video. </i>
    {/if}
</div>

<div id="videoholder">
    <div id="spinnerholder">
        <Spinner />
        {#if !source}
            {#await sleep(10) then _}
                <span class="light">
                    Still no source? Something could be wrong.
                    <br />
                    You could try playing with VLC instead!
                </span>
            {/await}
        {/if}
    </div>

    <video {id} style="display: none;" src={source}>
        <track src={sub_src} label="English" id="subs" kind="subtitles" srclang="en" default />
        {#each captions as [name, source]}
            <track src="/api/subtitles?query={name}" label={source} kind="subtitles" srclang={name} />
        {/each}
    </video>
    <div class="action-buttons">
        <!-- action buttons-->
        {#if !chromecast}
            <Cast {source} {metadata} />
            <StreamButton {source} {metadata} />
        {/if}
    </div>

    <Controls />
    <!--    <div id="cast_holder">-->
    <!--        <Cast {source} {metadata} />-->
    <!--    </div>-->
</div>

<style>
    #spinnerholder {
        /* center spinner */
        width: fit-content;
        margin: auto;
    }

    .action-buttons {
        display: flex;
        /*justify-content: space-evenly;*/
        column-gap: 20px;
    }

    .light {
        color: #ddd;
    }

    .tip .warning {
        color: #fd2;
        display: inherit;
        text-align: right;
        margin: 10px;
    }
</style>
