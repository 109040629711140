<script>
    import RequireSignin from "../components/RequireSignin.svelte";
    import Movie from "./Movie.svelte";

    export let id;
    let title = "Movie";
</script>

<RequireSignin {title}>
    <!-- use bind to get the value from the component here: -->
    <Movie {id} movie_id={id} bind:title />
</RequireSignin>
