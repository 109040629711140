<main>
    <div id="controls-tip" class="dropdown header__nav-item show">
        <a
            class="dropdown-toggle header__nav-link header__nav-link--more"
            href="#"
            role="button"
            id="dropdownMenuMore"
            data-toggle="dropdown"><i class="fa fa-question" /></a
        >

        <ul class="dropdown-menu header__dropdown-menu">
            <li><b>All Videos:</b></li>
            <li>F/Enter: Full Screen</li>
            <li>Space: Play/Pause</li>
            <li>C: Captions</li>
            <li>T: Theater mode</li>
            <li>R: Home</li>
            <hr />
            <li><b>TV:</b></li>
            <li>Left/Right: Previous/Next episode</li>
            <li>Down/Up: Previous/Next season</li>
        </ul>
    </div>
</main>

<style>
    #controls-tip {
        position: fixed;
        right: 30px;
        bottom: 30px;
        width: 70px;
        height: 70px;
    }

    #controls-tip li {
        color: rgba(255, 255, 255, 0.5);
    }

    #controls-tip .dropdown-menu {
        min-width: 300px;
    }
</style>
