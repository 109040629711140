<script>
    import Index from "./Index.svelte";
</script>

<svelte:head>
    <style>
        *:fullscreen .plyr__captions .plyr__caption {
            font-size: 2em;
        }
    </style>
</svelte:head>
<Index chromecast="1" />
