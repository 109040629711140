<script>
    import * as config from "../../settings";
    import { objectToQueryString } from "../js/premiumize";
    import { my_watchlist, redirect_uri, trakt } from "../js/trakt";
    import { traktstore } from "../js/stores";
    import TraktDataDisplay from "../components/TraktDataDisplay.svelte";
    import Spinner from "../components/Spinner.svelte";
    import RequireSignin from "../components/RequireSignin.svelte";
    import { params, upper } from "../js/helpers";

    let sort = params()["sorting"] || "rating";

    function set_sort(to) {
        sort = to;
        window.history.pushState(sort, sort, `?sorting=${sort}`);
    }

    const SORTING_OPTIONS = [
        "rank",
        "added",
        "released",
        // 'title',
        "rating",
    ];

    function login() {
        const client_id = config.trakt;

        window.location.href =
            "https://trakt.tv/oauth/authorize?" +
            objectToQueryString({
                client_id,
                redirect_uri: redirect_uri(),
                response_type: "code",
            });
    }

    if (!$traktstore["accesstoken"]) {
        login();
    }
</script>

<RequireSignin title="Watchlist">
    <div class="filter">
        <!-- empty filler -->
        <div class="container">
            <div class="row">
                <div class="col-md-9 col-0" />
                <div class="col-md-3 col-12">
                    <!-- filter item -->
                    <div class="filter__item" id="filter__quality">
                        <span class="filter__item-label">Sorting:</span>

                        <div
                            class="filter__item-btn dropdown-toggle"
                            role="navigation"
                            id="filter-quality"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <input type="button" value={upper(sort)} />
                            <span />
                        </div>

                        <ul class="filter__item-menu dropdown-menu scrollbar-dropdown" aria-labelledby="filter-quality">
                            {#each SORTING_OPTIONS as s}
                                <li on:click={(_) => set_sort(s)}>{upper(s)}</li>
                            {/each}
                        </ul>
                    </div>
                    <!-- end filter item -->
                </div>
            </div>
        </div>
    </div>
    {#await my_watchlist(sort)}
        <Spinner />
    {:then data}
        <div class="catalog">
            <div class="container">
                <div class="row row--grid">
                    {#each data as { movie }}
                        <TraktDataDisplay {movie} />
                    {/each}
                </div>
            </div>
        </div>
    {/await}
</RequireSignin>
